<div class="laoder" *ngIf="isLoaderVisible">
  <div class="loadingio-spinner-spinner-opzn0wto1h">
    <div class="ldio-xotm1esxoi">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>

<router-outlet *ngIf="!isLoaderVisible"></router-outlet>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Carousel } from 'src/app/models/carousel';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {
  carousel: Carousel[];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.loadCarouselList();
  }

  loadCarouselList(): void {
    this.carousel = [
      {
        id: 1,
        img: '1.jpg',
        alt: 'image',
        heading: 'Helical Gearbox for <br /> Reversing Rolling Mill',
        paragraph: 'For Bucket Wheel Drive',
        dataId: 2,
        subHeaderId: 2,
        subCategoryId: 2,
        imgId: 3,
      },
      {
        id: 2,
        img: '2.jpg',
        alt: 'image',
        heading: 'Custom Design <br /> Helical Gearbox',
        paragraph: 'for tube mill',
        dataId: 2,
        subHeaderId: 2,
        subCategoryId: 1,
        imgId: 5,
      },
      {
        id: 3,
        img: '3.jpg',
        alt: 'image',
        heading: 'Bevel Helical <br /> Gearbox',
        paragraph: 'For furnace pusher',
        dataId: null,
        subHeaderId: null,
        subCategoryId: null,
        imgId: null,
      },
      {
        id: 4,
        img: '4.jpg',
        alt: 'image',
        heading: 'Gearbox',
        paragraph: 'Rotary Kiln Drive',
        dataId: 2,
        subHeaderId: 2,
        subCategoryId: 4,
        imgId: 2,
      },
      {
        id: 5,
        img: '5.jpg',
        alt: 'image',
        heading: 'Integrated Drive <br /> Solution',
        paragraph: 'For Conveyor Drives',
        dataId: 2,
        subHeaderId: 2,
        subCategoryId: 2,
        imgId: 2,
      },
    ];
  }

  gotoProduct(obj: Carousel): void {
    // this.router.navigate(['/product'], {
    //   queryParams: {
    //     subCatId: obj.subCategoryId,
    //     imgListId: obj.imgListId
    //   }
    // });

    this.router.navigate(['/product'], {
      queryParams: {
        dataId: obj.dataId,
        subHeaderId: obj.subHeaderId,
        subCategoryId: obj.subCategoryId,
        imgId: obj.imgId,
      },
    });
  }
}
